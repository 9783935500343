import React from 'react';
import { Flex, Image, Heading } from 'theme-ui';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import emptyIllustration from '../images/empty-illustration.svg';

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Page non trouvée" />
    <Flex
      sx={{
        gridColumnStart: [1, null, 2, 1],
        gridColumnEnd: [2, 3, 5, 16],
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        p: [4, 2],
      }}
    >
      <Image src={emptyIllustration} alt="" sx={{ mb: 5 }} />
      <Heading as="h1" sx={{ fontSize: [3, 4] }}>
        Oops, désolé.e… Page non trouvée
      </Heading>
    </Flex>
  </Layout>
);

export default NotFoundPage;
